import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthFormStack, AuthHeader, PasswordInput } from '@/components/auth';
import { usePageTitle } from '@/hooks/usePageTitle';
import { useTrackPageView } from '@/hooks/useTrackPageView';
import { i18n } from '@/i18n';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Spinner,
  FormErrorMessage,
  AlertDescription,
} from '@/ui';
import { isGQLErrorOfType, type QueryError } from '@/utils/errors';
import { useResetPassword } from './useResetPassword';
import type { ResetPasswordMutationVariables } from './useResetPassword.generated';
import { useVerifyToken } from './useVerifyToken';

const ScreenError = ({
  title,
  children,
}: {
  title: string;
  children?: JSX.Element;
}) => {
  return (
    <Box>
      <Alert
        borderRadius="4"
        display="flex"
        justifyContent="center"
        py="40px"
        status="error"
        w="500px"
      >
        <VStack spacing="20px">
          <AlertIcon boxSize="40px" m={0} />
          <AlertTitle fontSize="lg">{title}</AlertTitle>
          {children && <AlertDescription>{children}</AlertDescription>}
        </VStack>
      </Alert>
    </Box>
  );
};

const getErrorMessage = (error: QueryError): string => {
  if (isGQLErrorOfType(error, 'ResetTokenInvalid')) {
    return i18n.t('resetPassword:errors.tokenInvalid');
  } else if (isGQLErrorOfType(error, 'ResetTokenExpired')) {
    return i18n.t('resetPassword:errors.tokenExpired');
  } else if (isGQLErrorOfType(error, 'ResetTokenUsed')) {
    return i18n.t('resetPassword:errors.tokenUsed');
  } else {
    return i18n.t('resetPassword:errors.tokenGenericError');
  }
};

type ResetFormData = Omit<ResetPasswordMutationVariables, 'token'>;

export const ResetPassword = (): JSX.Element => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation(['auth', 'resetPassword']);

  usePageTitle(t('resetPassword:page_title'));
  useTrackPageView('reset-password');

  const resetPassword = useResetPassword();
  const verifyToken = useVerifyToken(token);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetFormData>({
    defaultValues: {
      password: '',
    },
  });

  const handleReset: SubmitHandler<ResetFormData> = (data) => {
    if (token) {
      resetPassword.resetPassword(
        { ...data, token },
        {
          onSuccess: () => {
            navigate('/', { replace: true });
          },
        }
      );
    }
  };

  if (!token) {
    return <ScreenError title={t('resetPassword:errors.tokenMissing')} />;
  }

  if (verifyToken.isLoading) {
    return <Spinner size="lg" />;
  }

  if (verifyToken.isError) {
    const title = getErrorMessage(verifyToken.error);
    return (
      <ScreenError title={title}>
        <Trans i18nKey="errors.byline" ns="resetPassword">
          Please return to the <Link to="/reset-password">reset password</Link>{' '}
          screen and try again.
        </Trans>
      </ScreenError>
    );
  }

  return (
    <AuthFormStack
      header={
        <AuthHeader title={t('resetPassword:heading')}>
          {t('resetPassword:byline')}
        </AuthHeader>
      }
      onSubmit={handleSubmit(handleReset)}
    >
      {resetPassword.isError && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{getErrorMessage(resetPassword.error)}</AlertTitle>
        </Alert>
      )}

      <FormControl isInvalid={!!errors.password}>
        <FormLabel htmlFor="password">
          {t('resetPassword:inputs.password')}
        </FormLabel>
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <PasswordInput
              autoComplete="new-password"
              id="password"
              {...field}
            />
          )}
          rules={{
            required: t('auth:validation.required'),
            minLength: {
              value: 8,
              message: t('auth:validation.password_too_short'),
            },
          }}
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>

      <Button
        alignSelf="flex-start"
        isLoading={resetPassword.isPending}
        type="submit"
      >
        {t('resetPassword:buttons.submit')}
      </Button>
    </AuthFormStack>
  );
};
