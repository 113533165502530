import { useMutation, type MutateOptions } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCurrentUserContext } from '@/contexts';
import { ProfileFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from './useResetPassword.generated';

const query = gql`
  ${ProfileFragment}
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      ...Profile
    }
  }
`;

export const useResetPassword = () => {
  const { setUser } = useCurrentUserContext();

  const { mutate, ...result } = useMutation<
    ResetPasswordMutation,
    QueryError,
    ResetPasswordMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<ResetPasswordMutation, ResetPasswordMutationVariables>(
        query,
        variables
      ),
  });

  const resetPassword = async (
    variables: ResetPasswordMutationVariables,
    {
      onSuccess,
      ...options
    }: MutateOptions<
      ResetPasswordMutation,
      QueryError,
      ResetPasswordMutationVariables
    > = {}
  ) => {
    mutate(variables, {
      onSuccess: (result, ...args) => {
        setUser(result.resetPassword);
        onSuccess?.(result, ...args);
      },
      ...options,
    });
  };

  return {
    ...result,
    resetPassword,
  };
};
