import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: '603-brewery-events',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#389646',
    '--secondary-color': '#EFEFEF',
  },
  header: {
    href: 'https://www.shop603brewery.com/classes',
    banner,
    logo,
  },
};
