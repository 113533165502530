import { useEffect } from 'react';
import { useToken } from '@/ui';

export const useMetaThemeColor = (color: string) => {
  const colorValue = useToken('colors', color);

  useEffect(() => {
    document.querySelectorAll("meta[name='theme-color']").forEach((meta) => {
      meta.setAttribute('content', colorValue);
    });
  }, [colorValue]);
};
